import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { DividerLeft } from '../../../partials/global partials/Hr';

export const Email = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_m76v2vj',
        'template_c3qrnep',
        form.current,
        'TziPMqMbUpRC227LI',
      )
      .then(
        (result) => {
          console.log(result.text, result.status);
          alert('Hey! I received your message. Thank you!');
        },
        (error) => {
          console.log(error.text);
          alert(`${error.text} happened`);
        },
      );

    e.target.reset();
  };

  return (
    <>
      <div className="row mb-5 mt-5">
        <div className="col">
          <h6 className="font-weight-lg  mb-0 text-uppercase mb-2">
            Send me an email
          </h6>
          <DividerLeft />
        </div>
      </div>

      {/* Start Contact Form */}
      <div className="row justify-content-center">
        <div className="col-md-12">
          <form className="contact-form" ref={form} onSubmit={sendEmail}>
            <div className="row">
              <div className="col-sm-6" data-aos="fade-up-right">
                <div className="outer radius-2 mb-3">
                  <input
                    className="inner form-control  h-100 "
                    placeholder="Name"
                    name="name"
                    type="text"
                    required
                  />
                </div>
              </div>

              <div className="col-sm-6" data-aos="fade-up-left">
                <div className="outer radius-2 mb-3">
                  <input
                    className="inner form-control  h-100 "
                    placeholder="Email"
                    name="email"
                    type="email"
                    required
                  />
                </div>
              </div>

              <div className="col-sm-6">
                <div className="outer radius-2 mb-3">
                  <input
                    className="inner form-control  h-100 "
                    name="compagny"
                    placeholder="Compagny"
                    type="text"
                  />
                </div>
              </div>

              <div className="col-sm-6">
                <div className="outer radius-2 mb-3">
                  <input
                    className="inner form-control  h-100 "
                    name="subject"
                    placeholder="Subject"
                    type="text"
                    required
                  />
                </div>
              </div>

              <div className="col-12">
                <div className="outer radius-2 mb-3">
                  <textarea
                    className="radius-2 inner form-control resize-n "
                    name="message"
                    placeholder="Message"
                    required
                    rows="6"
                  ></textarea>
                </div>
              </div>

              <div className="col-12">
                <div className="outer radius-2 d-inline-block text-center">
                  <button
                    type="submit"
                    className="send inner radius-2 d-block py-2 px-4 font-med font-weight-md"
                  >
                    Send message
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* End Contact Form */}
    </>
  );
};
