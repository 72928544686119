import React from 'react';
import { MobileHeader } from '../components/MobileHeader';
import { SideBar } from '../components/SideBar';
import { Content } from './dist/Content';

export const Home = () => {
  return (
    <>
      <MobileHeader />
      <SideBar>
        <Content />
      </SideBar>
    </>
  );
};
