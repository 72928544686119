import React from 'react';
import { BlogLists } from './dist/BlogLists';
import { DividerLeft } from '../partials/global partials/Hr';

export const Blogs = () => {
  return (
    <>
      <section id="blogs" className="shadow bg-dark radius-2 p-5 mb-5">
        <div className="row mb-5">
          <div className="col">
            <h6 className="font-weight-lg  mb-0 text-uppercase mb-2">
              Latest News
            </h6>

            <DividerLeft />
          </div>
        </div>

        <BlogLists />
      </section>
    </>
  );
};
