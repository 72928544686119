import React from 'react';
import {
  blockquoteBlog,
  blogWithImage,
  blogWithOutImage,
} from '../../../data/blogData';
import { BsChatFill, BsPersonFill, BsTagFill } from 'react-icons/bs';
import { DividerMdCenter } from '../../partials/global partials/Hr';

export const BlogLists = () => {
  return (
    <>
      <div className="row">
        {blogWithImage.map((data) => (
          <div className="col-md-12" key={data.title}>
            <div className="mb-4 item">
              <a className="radius-1 shadow-inner d-block " href={data.url}>
                <div className="p-2">
                  <div className="shadow radius-1">
                    <img
                      className="img-fluid radius-1"
                      src={data.img}
                      alt={data.title}
                    />
                  </div>
                </div>
              </a>
            </div>

            <div>
              <h1 className="h5 font-weight-md d-inline-block">
                <a href={data.url}>{data.title}</a>
              </h1>
              <span className="d-inline-block shadow-inner text-secondary radius-1 font-sm mb-3 mb-lg-0  px-3">
                {data.date}
              </span>
            </div>
            <ul className="list-inline font-sm">
              <li className="list-inline-item">
                <span className="me-1">
                  <BsPersonFill />
                </span>
                <span>
                  Post by
                  <a href="#"> {data.postBy}</a>
                </span>
              </li>
              <li className="list-inline-item">
                <span>
                  <BsTagFill />
                </span>
                <span>
                  Tagged in
                  <a href="#"> {data.tag}</a>
                </span>
              </li>
              <li className="list-inline-item">
                <span className="me-1">
                  <BsChatFill />
                </span>
                <span>
                  Comments
                  <a href="#"> {data.comments}</a>
                </span>
              </li>
            </ul>
            <hr className="mt-3 mb-3" />
            <p>{data.details}</p>
            <div className="outer radius-2 d-inline-block">
              <a
                href={data.url}
                className="inner radius-2 d-block px-4 py-2 font-med text-alt font-weight-md"
              >
                Continue reading
              </a>
            </div>
          </div>
        ))}

        <DividerMdCenter />

        {blogWithOutImage.map((data) => (
          <div className="col-md-12" key={data.title}>
            <div>
              <h1 className="h5 font-weight-md d-inline-block">
                <a href={data.url}>{data.title}</a>
              </h1>
              <span className="d-inline-block shadow-inner text-secondary radius-1 font-sm mb-3 mb-lg-0  px-3">
                {data.date}
              </span>
            </div>

            <ul className="list-inline font-sm">
              <li className="list-inline-item">
                <span className="me-1">
                  <BsPersonFill />
                </span>
                <span>
                  Post by
                  <a href="#"> {data.postBy}</a>
                </span>
              </li>
              <li className="list-inline-item">
                <span>
                  <BsTagFill />
                </span>
                <span>
                  Tagged in
                  <a href="#"> {data.tag}</a>
                </span>
              </li>
              <li className="list-inline-item">
                <span className="me-1">
                  <BsChatFill />
                </span>
                <span>
                  Comments
                  <a href="#"> {data.comments}</a>
                </span>
              </li>
            </ul>

            <hr className="mt-3 mb-3" />
            <p>{data.details}</p>
            <div className="outer radius-2 d-inline-block">
              <a
                href={data.url}
                className="inner radius-2 d-block px-4 py-2 font-med text-alt font-weight-md"
              >
                Continue reading
              </a>
            </div>
          </div>
        ))}

        <DividerMdCenter />

        {blockquoteBlog.map((data) => (
          <div className="col-md-12" key={data.title}>
            <div>
              <h1 className="h5 font-weight-md d-inline-block">
                <a href={data.url}>{data.title}</a>
              </h1>
              <span className="d-inline-block shadow-inner text-secondary radius-1 font-sm mb-3 mb-lg-0  px-3">
                {data.date}
              </span>
            </div>

            <ul className="list-inline font-sm">
              <li className="list-inline-item">
                <span className="me-1">
                  <BsPersonFill />
                </span>
                <span>
                  Post by
                  <a href="#"> {data.postBy}</a>
                </span>
              </li>
              <li className="list-inline-item">
                <span>
                  <BsTagFill />
                </span>
                <span>
                  Tagged in
                  <a href="#"> {data.tag}</a>
                </span>
              </li>
              <li className="list-inline-item">
                <span className="me-1">
                  <BsChatFill />
                </span>
                <span>
                  Comments
                  <a href="#"> {data.comments}</a>
                </span>
              </li>
            </ul>

            <div className="px-5 mt-4 p-m-0-sm">
              <div className="p-2  radius-1 shadow-inner ">
                <div className="shadow radius-1 p-5">
                  <div className="media  align-items-center">
                    <div className="radius-5 me-4 d-none d-lg-block">
                      <div className="shadow-inner  ui-icon ui-icon-xl ui-icon-xxl">
                        <div className="ui-icon-inner">
                          <img
                            src={data.img}
                            alt={data.title}
                            width="100%"
                            height="100%"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="media-body">
                      <blockquote className="mb-2 font-lg me-0 me-lg-4">
                        {data.details}
                      </blockquote>
                      <h6 className="font-med font-weight-xmd mb-0">
                        {data.author}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="row mt-5">
        <div className="col text-center">
          <div className="outer radius-2 d-inline-block">
            <a
              className="inner radius-2 d-block px-4 py-2 font-med font-weight-md"
              href="#"
            >
              View all blog posts
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
