import SupportIcon from '../assets/images/icon/Dark theme/internet.gif';
import DesignIcon from '../assets/images/icon/Dark theme/edit.gif';
import MarketingIcon from '../assets/images/icon/Dark theme/archer.gif';
import WebIcon from '../assets/images/icon/Dark theme/design.gif';
import CloudIcon from '../assets/images/icon/Dark theme/clode.gif';
import BrandIcon from '../assets/images/icon/Dark theme/brand.gif';

// Testimonial

import TestimonialImg from '../assets/images/faha1999.webp';

export const personalInfo1 = [
  {
    title: 'Name',
    details: 'Kawsar Ahmed Fahad',
  },
  {
    title: 'Birth',
    details: 'April 12, 1999',
  },
  {
    title: 'City',
    details: 'Sylhet, Bangladesh',
  },
];

export const personalInfo2 = [
  {
    title: 'Email',
    details: 'kafahad1999@gmail.com',
  },
  {
    title: 'WeChat',
    details: 'kafaha1999',
  },
  {
    title: 'GitHub',
    details: 'faha1999',
  },
];

export const coding = [
  {
    title: 'Html-CSS',
    style: { width: '90%' },
  },
  {
    title: 'Javascript',
    style: { width: '85%' },
  },
  {
    title: 'React',
    style: { width: '80%' },
  },
  {
    title: 'SASS',
    style: { width: '95%' },
  },
];

export const design = [
  {
    title: 'Photoshop',
    style: { width: '82%' },
  },
  {
    title: 'Illustrator',
    style: { width: '89%' },
  },
  {
    title: 'Figma',
    style: { width: '80%' },
  },
];

export const expediency = [
  {
    title: 'Responsible',
    style: { width: '100%' },
  },
  {
    title: 'Creative',
    style: { width: '90%' },
  },
  {
    title: 'Multitasking',
    style: { width: '84%' },
  },
];

export const educationDetailsPresent = [
  {
    program: 'Nanodegree Program – Cloud Architect using Microsoft Azure',
    duration: 'December 2021 - ',
    current: 'Present',
    institution: 'Institution',
    org: 'Bertelsmann',
    place: 'Platform',
    address: 'Udacity',
  },
  {
    program: 'Bachelor Degree – Computer Science & Technology',
    // program: 'Bachelor Degree – Business Administration',
    duration: 'Sept 2021 - ',
    current: 'Present',
    institution: 'Institution',
    // org: 'Shanghai Business School',
    org: 'Shanghai University',
    place: 'Place',
    // address: '123 Fengpu Blvd, Fengxian District, Shanghai, China, 201499',
    address: 'Jufengyuan Rd, Baoshan, Shanghai, P. R. China',
  },
];

export const educationDetails = [
  {
    program: 'Bachelor Degree – Software Engineering',
    duration: 'Sept 2018 -  July 2021',
    institution: 'Institution',
    org: 'Hunan University of Science & Engineering',
    place: 'Place',
    address: 'Yongzhou Shi, Hunan Sheng, P. R. China',
  },
  {
    program: 'Cultural Studies – Chinese language & culture',
    duration: 'Sept 2018 -  July 2019',
    institution: 'Institution',
    org: 'Hunan University of Science & Engineering',
    place: 'Place',
    address: 'Yongzhou Shi, Hunan Sheng, P. R. China',
  },
];

export const experienceDetailsPresent = [
  {
    title: 'Front-end Developer – manushoye.com',
    duration: 'Feb 2020 - ',
    current: 'Present',
    details: `Manushoye.com is a platform connecting help-seeking people with individual help providers by maintaining help seeker's privacy & dignity strictly. Here anyone can ask for any kinds of help as long as It's legal with a very easy procedure; at the same time, anyone at ease can find and provide assistance to help seeker from any locality he wants to. Throughout the process manushoye wouldn’t charge a single penny; means it completely free to use.
    `,
    quotation: 'Our goal is to bring harmony to society using ICT.',
  },
  {
    title: 'Front-end Trainer – ADCTI',
    duration: 'Sept 2019 - ',
    current: 'Present',
    details: `Abacus Digital Computer institute has been providing quality education since 2008. It is registered by the Bangladesh Technical Education Commission. We can proudly say that I.T. batches have been successfully completed so far. In addition to this, professional and work-oriented courses are offered under the guidance of experienced professional teachers.
    `,
  },
];

export const experienceDetails = [
  {
    title: 'Chief Operating Officer (COO) – kisob.com',
    duration: 'February 2021 - October 2021',
    details: `Kisob is an e-commerce platform for Books, Fashion &amp; Lifestyle,Electronics,Mobile phones, Daily essential, Food items and many more products at your doorstep with quickest home delivery service.    `,
  },

  {
    title: 'Product Designer – kisob.com',
    duration: 'Noverber 2020 - February 2021',
    details: `Kisob is an e-commerce platform for Books, Fashion & Lifestyle,Electronics,Mobile phones, Daily essential, Food items and many more products at your doorstep with quickest home delivery service. We offer a wide range of quality products from renowned brands in Bangladesh with a Safest,fastest and easiest purchasing online shopping experience. Kisob also offers countrywide shipping and happy returns! And we offer local sellers and manufacturers to sell their products to grow business online as well. For customer convenience we have several payment options including credit/debit cards bkash,Rocket, Nagad and cash on delivery. Customer support, belief and satisfaction are our main strength.    `,
  },

  {
    title: 'IELTS Coordinator – Ficc IELTS',
    duration: 'January 2020 - July 2020',
    details: `As an IELTS coordinator, I prepared lecture notes, materials and practice tests. Here I host speaking clubs and other types of events such as off-plan, open discussions, quizzes, tests and other events. `,
  },

  {
    title: 'Data Analyst – Ficc',
    duration: 'January 2020 - October 2020',
    details: `Farees International Career Counselling FICC is a career Counseling firm. There have a lot of students data. My duty here analyst all data and giving them a report which helps them got more connection with students.`,
  },
];

export const servicePart1 = [
  {
    img: SupportIcon,
    title: 'Great Support',
    details: `Build your dream website with great support from our skilled developers.`,
  },
  {
    img: DesignIcon,
    title: 'Design',
    details: `Clean and modern design that communicates professionalism and trustworthiness.`,
  },
  {
    img: MarketingIcon,
    title: 'Marketing',
    details: `From SEO to social media, we have the expertise to market your business.`,
  },
];

export const servicePart2 = [
  {
    img: WebIcon,
    title: 'Web Design',
    details: `Modern and responsive web design that ensures optimal user experience on all devices.`,
  },
  {
    img: CloudIcon,
    title: 'Development',
    details: `Agile web development that guarantees high-quality, timely, cost-effective results, user-friendly and secure websites.`,
  },
  {
    img: BrandIcon,
    title: 'Branding',
    details: `Professional branding solutions to elevate your online presence and boost brand recognition.`,
  },
];

export const TestimonialData = [
  {
    img: TestimonialImg,
    review:
      'Mountain Pose is the base for all standing poses it gives you a sense of how to ground in to your feet and feel the earth below you.',
    name: 'Jeremy Spivak',
    title: 'Business Manager',
  },
  {
    img: TestimonialImg,
    review:
      'Mountain Pose is the base for all standing poses it gives you a sense of how to ground in to your feet and feel the earth below you.',
    name: 'Jeremy Spivak',
    title: 'Business Manager',
  },
  {
    img: TestimonialImg,
    review:
      'Mountain Pose is the base for all standing poses it gives you a sense of how to ground in to your feet and feel the earth below you.',
    name: 'Jeremy Spivak',
    title: 'Business Manager',
  },
  {
    img: TestimonialImg,
    review:
      'Mountain Pose is the base for all standing poses it gives you a sense of how to ground in to your feet and feel the earth below you.',
    name: 'Jeremy Spivak',
    title: 'Business Manager',
  },
  {
    img: TestimonialImg,
    review:
      'Mountain Pose is the base for all standing poses it gives you a sense of how to ground in to your feet and feel the earth below you.',
    name: 'Jeremy Spivak',
    title: 'Business Manager',
  },
  {
    img: TestimonialImg,
    review:
      'Mountain Pose is the base for all standing poses it gives you a sense of how to ground in to your feet and feel the earth below you.',
    name: 'Jeremy Spivak',
    title: 'Business Manager',
  },
  {
    img: TestimonialImg,
    review:
      'Mountain Pose is the base for all standing poses it gives you a sense of how to ground in to your feet and feel the earth below you.',
    name: 'Jeremy Spivak',
    title: 'Business Manager',
  },
];
