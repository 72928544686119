import easyBank from '../assets/images/portfolio/Easybank-landing-page.webp';
import recipes from '../assets/images/portfolio/web/recipes.jpg';

export const frontendMentorData = [
  {
    img: easyBank,
    title: 'Easybank landing page',
    difficulty: 'Intermediate',
    live: 'https://faha1999.github.io/easybank-landing-page/',
    repo: 'https://github.com/faha1999/easybank-landing-page',
    tryout:
      'https://www.frontendmentor.io/challenges/easybank-landing-page-WaUhkoDN',
  },
  {
    img: recipes,
    title: 'Manage landing page',
    difficulty: 'Intermediate',
    live: 'https://manage-landing-page-faha1999.netlify.app/',
    repo: 'https://github.com/faha1999/manage-landing-page',
    tryout:
      'https://www.frontendmentor.io/challenges/manage-landing-page-SLXqC6P5',
  },
  {
    img: recipes,
    title: 'Fylo dark theme landing page',
    difficulty: 'Junior',
    live: 'https://fylo-dark-theme-landing-page-faha1999.vercel.app/',
    repo: 'https://github.com/faha1999/fylo-dark-theme-landing-page',
    tryout:
      'https://www.frontendmentor.io/challenges/fylo-dark-theme-landing-page-5ca5f2d21e82137ec91a50fd',
  },
  {
    img: recipes,
    title: 'Room homepage',
    difficulty: 'Intermediate',
    live: 'https://room-homepage-faha1999.vercel.app/',
    repo: 'https://github.com/faha1999/room-homepage',
    tryout: 'https://www.frontendmentor.io/challenges/room-homepage-BtdBY_ENq',
  },
  {
    img: recipes,
    title: 'Bookmark landing page',
    difficulty: 'Intermediate',
    live: 'https://bookmark-landing-page-faha1999.vercel.app/',
    repo: 'https://github.com/faha1999/bookmark-landing-page',
    tryout:
      'https://www.frontendmentor.io/challenges/bookmark-landing-page-5d0b588a9edda32581d29158',
  },
  {
    img: recipes,
    title: 'Launch countdown timer',
    difficulty: 'Intermediate',
    live: 'https://launch-countdown-timer-faha1999.vercel.app/',
    repo: 'https://github.com/faha1999/launch-countdown-timer',
    tryout:
      'https://www.frontendmentor.io/challenges/launch-countdown-timer-N0XkGfyz-',
  },
  {
    img: recipes,
    title: 'Interactive comments section',
    difficulty: 'Intermediate',
    live: 'https://interactive-comments-section-faha1999.vercel.app/',
    repo: 'https://github.com/faha1999/interactive-comments-section',
    tryout:
      'https://www.frontendmentor.io/challenges/interactive-comments-section-iG1RugEG9',
  },
  {
    img: recipes,
    title: 'Todo app',
    difficulty: 'Intermediate',
    live: 'https://todo-app-faha1999.vercel.app/',
    repo: 'https://github.com/faha1999/todo-app',
    tryout: 'https://www.frontendmentor.io/challenges/todo-app-Su1_KokOW',
  },
  {
    img: recipes,
    title: 'E-commerce product page',
    difficulty: 'Intermediate',
    live: 'https://e-commerce-product-page-faha1999.vercel.app/',
    repo: 'https://github.com/faha1999/e-commerce-product-page',
    tryout:
      'https://www.frontendmentor.io/challenges/ecommerce-product-page-UPsZ9MJp6',
  },
  {
    img: recipes,
    title: 'QR code component',
    difficulty: 'Newbie',
    live: 'https://faha1999.github.io/qr-code-component/',
    repo: 'https://github.com/faha1999/qr-code-component',
    tryout:
      'https://www.frontendmentor.io/challenges/qr-code-component-iux_sIO_H',
  },
  {
    img: recipes,
    title: 'NFT preview card component',
    difficulty: 'Newbie',
    live: 'https://faha1999.github.io/NFT-preview-card-component/',
    repo: 'https://github.com/faha1999/NFT-preview-card-component',
    tryout:
      'https://www.frontendmentor.io/challenges/nft-preview-card-component-SbdUL_w0U',
  },
  {
    img: recipes,
    title: 'Product preview card component',
    difficulty: 'Newbie',
    live: 'https://faha1999.github.io/Product-preview-card-component/',
    repo: 'https://github.com/faha1999/Product-preview-card-component',
    tryout:
      'https://www.frontendmentor.io/challenges/product-preview-card-component-GO7UmttRfa',
  },
  {
    img: recipes,
    title: 'facebook',
    difficulty: '',
    live: '',
    repo: '',
    tryout: '',
  },
  {
    img: recipes,
    title: 'facebook',
    difficulty: '',
    live: '',
    repo: '',
    tryout: '',
  },
  {
    img: recipes,
    title: 'facebook',
    difficulty: '',
    live: '',
    repo: '',
    tryout: '',
  },
  {
    img: recipes,
    title: 'facebook',
    difficulty: '',
    live: '',
    repo: '',
    tryout: '',
  },
  {
    img: recipes,
    title: 'facebook',
    difficulty: '',
    live: '',
    repo: '',
    tryout: '',
  },
];
