import React from 'react';
import { motion } from 'framer-motion';

const up = {
  offscreen: { y: -100, opacity: 0 },
  onscreen: {
    x: 0,
    y: 0,
    opacity: 1,
    transition: { duration: 1 },
  },
};

const Right = {
  offscreen: { x: 50, opacity: 0 },
  onscreen: {
    x: 0,
    y: 0,
    opacity: 1,
    transition: { duration: 1 },
  },
};

export const DividerLgCenter = () => {
  return (
    <>
      <motion.div
        className="col-md-12 mb-5 mt-3 v-centred"
        initial={'offscreen'}
        whileInView={'onscreen'}
        viewport={{ once: false, amount: 0.5 }}
        transition={{ staggerChildren: 0.5 }}
        variants={up}
      >
        <hr className="divider divider-lg divider-center " />
      </motion.div>
    </>
  );
};

export const DividerLeft = () => {
  return (
    <>
      <motion.div
        initial={'offscreen'}
        whileInView={'onscreen'}
        viewport={{ once: false, amount: 0.5 }}
        variants={Right}
      >
        <hr className="divider divider-left divider-lg float-left" />
      </motion.div>
    </>
  );
};

export const DividerMdCenter = () => {
  return (
    <>
      <motion.div
        className="col-md-12 mt-5 mb-5 v-centred"
        initial={'offscreen'}
        whileInView={'onscreen'}
        viewport={{ once: false, amount: 0.5 }}
        variants={up}
      >
        <hr className="divider divider-md divider-center" />
      </motion.div>
    </>
  );
};
