import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'react-scroll';
import { sidebarMenu } from '../data/sidebarMenu';

import { BsTextIndentRight } from 'react-icons/bs';
import { BsTextIndentLeft } from 'react-icons/bs';

import faha1999 from '../assets/images/faha1999.webp';

export const SideBar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => setIsOpen(!isOpen);

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: 'auto',
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <div className="mainContainer container-fluid">
      <motion.header
        animate={{
          width: isOpen ? '200px' : '55px',
          height: isOpen ? 'auto' : '30rem',

          transition: {
            duration: 0.5,
            type: 'spring',
            damping: 10,
          },
        }}
        className="sidebar py-5 my-4 shadow bg-dark radius-2"
      >
        <div
          className="bars"
          style={{
            paddingRight: isOpen ? '' : '2.4rem',
          }}
        >
          {isOpen ? (
            <BsTextIndentRight onClick={toggle} />
          ) : (
            <BsTextIndentLeft style={{ color: '#e57525' }} onClick={toggle} />
          )}
        </div>

        <div
          className={isOpen ? 'top-section' : ' pt-5'}
          style={{
            width: isOpen ? '' : '45px',
            height: isOpen ? '' : '45px',
            margin: isOpen ? '' : '0 auto',
            padding: isOpen ? '' : '0',
            paddingBottom: isOpen ? '' : '3rem',
          }}
        >
          <AnimatePresence>
            <>
              <motion.div
                className="profile-image"
                style={{
                  width: isOpen ? '' : '45px',
                  height: isOpen ? '' : '45px',
                  paddingBottom: isOpen ? '' : '2rem',
                }}
              >
                <div
                  className="profile-image-border"
                  style={{
                    boxShadow: isOpen
                      ? ''
                      : 'inset 1px 1px 1px #202125, inset -1px -1px 1px #353535',
                    width: isOpen ? '' : '25px',
                    height: isOpen ? '' : '25px',
                  }}
                >
                  <motion.img
                    style={{
                      width: isOpen ? '' : '35px',
                      height: isOpen ? '' : '35px',
                      marginTop: isOpen ? '' : '-14px',
                      marginLeft: isOpen ? '' : '-9.2px',
                    }}
                    className="rounded-circle"
                    src={faha1999}
                    alt="Kawsar Ahmed Fahad"
                  />
                </div>
              </motion.div>

              {isOpen && (
                <motion.div
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="mt-4 text-center"
                >
                  <h3 className="font-weight-xmd mb-0 orange-text-color font-med">
                    Kawsar Ahmed Fahad
                  </h3>
                  <h6 className="text-alt mt-2 mb-0 font-xsm">
                    Front-end Developer
                  </h6>
                </motion.div>
              )}
            </>
          </AnimatePresence>
        </div>

        <nav className="sidebarMenu main-nav clearfix tabbed">
          <ul className={isOpen ? 'ps-3 pe-3' : 'ps-0'}>
            {sidebarMenu.map((route) => (
              <li
                className={isOpen ? 'outer radius-2 mb-3' : 'radius-2 mb-3'}
                key={route.id}
              >
                <Link
                  activeClass="active"
                  to={route.id}
                  key={route.name}
                  className="link inner radius-2"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={500}
                >
                  <div className="media align-items-center">
                    <div className="media-body text-center gap-3">
                      <div className="icon">
                        <img
                          src={route.icon}
                          alt={route.name}
                          width="100%"
                          height="100%"
                        />
                      </div>

                      <AnimatePresence>
                        {isOpen && (
                          <motion.div
                            variants={showAnimation}
                            initial="hidden"
                            animate="show"
                            exit="hidden"
                            className="text font-weight-xmd font-sm text-uppercase"
                          >
                            {route.name}
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </nav>

        {isOpen && (
          <div className="text-center">
            <p className="font-med mb-2 text-muted orange-text-color">
              &copy; faha1999
            </p>
          </div>
        )}
      </motion.header>

      <motion.main
        className="w-100"
        animate={{
          paddingLeft: isOpen ? '210px' : '70px',

          transition: {
            duration: 0.5,
            type: 'spring',
            damping: 10,
          },
        }}
      >
        {children}
      </motion.main>
    </div>
  );
};
