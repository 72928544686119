import React from 'react';
import { motion } from 'framer-motion';

const Name = {
  hidden: {
    opacity: 0,
    y: -40,
  },
  visible: {
    opacity: 1,
    y: 0,

    transition: {
      delay: 1,
      ease: 'easeInOut',
    },
  },
};

const Gretting = {
  hidden: {
    opacity: 0,
    y: -60,
  },
  visible: {
    opacity: 1,
    y: 0,

    transition: {
      delay: 2,
      ease: 'easeInOut',
    },
  },
};

const textVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,

    transition: {
      delay: 3,
      duration: 1,
      yoyo: Infinity, // repeats infinite times
      ease: 'easeInOut',
    },
  },
};

export const Loader = () => {
  return (
    <div className="LoaderContainer">
      <div className="shadow radius-1 p-5">
        <motion.span
          className="name"
          variants={Name}
          initial="hidden"
          animate="visible"
        >
          Hi, I'm Kawsar Ahmed Fahad
        </motion.span>

        <br />

        <motion.h6
          className="greeting"
          variants={Gretting}
          initial="hidden"
          animate="visible"
        >
          Nice to meet you.
        </motion.h6>
        <br />

        <motion.span
          className="text"
          variants={textVariants}
          initial="hidden"
          animate="visible"
        >
          Be patient , I'm almost there!!!
        </motion.span>
      </div>
    </div>
  );
};
