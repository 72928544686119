// Icons GIF
import homeIcon from '../assets/images/icon/Dark theme/home.gif';
import aboutIcon from '../assets/images/icon/Dark theme/avatar.gif';
import portfolioIcon from '../assets/images/icon/Dark theme/man.gif';
import blogIcon from '../assets/images/icon/Dark theme/blog.gif';
import contactIcon from '../assets/images/icon/Dark theme/map.gif';

export const sidebarMenu = [
  {
    name: 'Home',
    icon: homeIcon,
    id: 'home',
  },
  {
    name: ' About',
    icon: aboutIcon,
    id: 'about',
  },
  {
    name: 'Portfolio',
    icon: portfolioIcon,
    id: 'portfolio',
  },
  {
    name: 'Blogs',
    icon: blogIcon,
    id: 'blogs',
  },
  {
    name: 'Contact',
    icon: contactIcon,
    id: 'contact',
  },
];
