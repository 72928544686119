import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Layout } from './components/blog/BlogContent/Layout';
import { Loader } from './components/loader/Loader';
import { PortfolioLayout } from './components/Portfolio/Portfilo Details/PortfolioLayout';
import { TsParticles } from './components/tsparticles/TsParticles';
import { Blog } from './pages/Blog';
import { Home } from './pages/Home';

export const App = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 3000);
  }, []);

  return (
    <>
      <TsParticles />
      <AnimatePresence>{loaded ? null : <Loader />}</AnimatePresence>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/content/*" element={<Layout />} />
        <Route path="/portfolio/*" element={<PortfolioLayout />} />
      </Routes>
    </>
  );
};
