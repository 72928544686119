import React from 'react';
import { blockquoteBlog } from '../../../../../data/blogData';
import { ContentCommentForm } from '../../../../partials/global partials/ContentCommentForm';
import { ContentSocialIcon } from '../../../../partials/global partials/ContentSocialIcon';
import { DividerLeft } from '../../../../partials/global partials/Hr';
import { Comments } from '../Blog1/partials/Comments';

export const ContentThree = () => {
  return (
    <>
      <section className="shadow bg-dark radius-2 p-5 mb-5 BlogContent">
        <div className="row mb-5">
          <div className="col">
            <h6 className="font-weight-lg text-uppercase mb-2">The blog</h6>
            <DividerLeft />
          </div>
        </div>

        {/* Start blog post */}
        <div className="row align-items-center justify-content-center">
          <div className="col-md-12">
            <div>
              <h1 className="h5 font-weight-md d-inline-block">
                A blockquote blog post style display
              </h1>
              <span className="d-inline-block shadow-inner orange-text-color radius-1 font-xsm  mb-3 mb-lg-0  px-3">
                13 Dec
              </span>
            </div>

            <ul className="list-inline font-xsm ">
              <li className="list-inline-item">
                <span className=" fas fa-user mr-1 "></span>
                <span>
                  Post by
                  <a href="#"> Kawsar Ahmed Fahad</a>
                </span>
              </li>
              <li className="list-inline-item">
                <span className=" fas fa-tag "></span>
                <span>
                  Tagged in
                  <a href="#"> Web Design</a>
                </span>
              </li>
              <li className="list-inline-item">
                <span className=" fas fa-comment mr-1"></span>
                <span>
                  Comments
                  <a href="#"> (2)</a>
                </span>
              </li>
            </ul>

            <hr className="mt-3 mb-3" />

            {blockquoteBlog.map((data) => (
              <div key={data.title}>
                <div className="px-5 mt-4 p-m-0-sm">
                  <div className="p-2 ">
                    <div className="shadow radius-1 p-5">
                      <div
                        className="media  align-items-center"
                        style={{ display: 'flex' }}
                      >
                        <div className="radius-5 me-4 d-none d-lg-block">
                          <div className="shadow-inner  ui-icon ui-icon-xl ui-icon-xxl">
                            <div className="ui-icon-inner">
                              <img src={data.img} alt={data.title} />
                            </div>
                          </div>
                        </div>
                        <div
                          className="media-body"
                          style={{ display: 'block' }}
                        >
                          <blockquote className="mb-2 font-lg me-0 me-lg-4">
                            {data.details}
                          </blockquote>
                          <h6 className="font-med font-weight-xmd mb-0">
                            {data.author}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <ContentSocialIcon />
          <Comments />
          <ContentCommentForm />
        </div>
        {/* End blog post */}
      </section>
    </>
  );
};
