import React from 'react';
import { ContentCommentForm } from '../../../../partials/global partials/ContentCommentForm';
import { ContentSocialIcon } from '../../../../partials/global partials/ContentSocialIcon';
import { DividerLeft } from '../../../../partials/global partials/Hr';
import { Comments } from '../Blog1/partials/Comments';
import { frontendMentorData } from '../../../../../data/frontendMentorData';

export const ContentTwo = () => {
  return (
    <>
      <section className="shadow bg-dark radius-2 p-5 mb-5 BlogContent">
        <div className="row mb-5">
          <div className="col">
            <h6 className="font-weight-lg text-uppercase mb-2">The blog</h6>
            <DividerLeft />
          </div>
        </div>

        {/* Start blog post */}
        <div className="row align-items-center justify-content-center">
          <div className="col-md-12">
            <div>
              <h1 className="h5 font-weight-md d-inline-block">
                Frontend Mentor Challenges Solutions
              </h1>
              <span className="d-inline-block shadow-inner orange-text-color radius-1 font-xsm  mb-3 mb-lg-0  px-3">
                10 November 2022
              </span>
            </div>

            <ul className="list-inline font-xsm ">
              <li className="list-inline-item">
                <span className=" fas fa-user mr-1 "></span>
                <span>
                  Post by
                  <a href="#"> Kawsar Ahmed Fahad</a>
                </span>
              </li>
              <li className="list-inline-item">
                <span className=" fas fa-tag "></span>
                <span>
                  Tagged in
                  <a href="#"> Web Development</a>
                </span>
              </li>
              <li className="list-inline-item">
                <span className=" fas fa-comment mr-1"></span>
                <span>
                  Comments
                  <a href="#"> (2)</a>
                </span>
              </li>
            </ul>

            <hr className="mt-3 mb-3" />

            <h1 className="BlogTitle">About Frontend Mentor</h1>

            <p>
              Frontend Mentor provides designs so that anyone can practice their
              front-end skills using a real-life workflow. Their challenges
              include designs (both mobile and desktop), assets, and a style
              guide to get started. It helps improve front-end skills by
              building real projects. Solve real-world HTML, CSS, and JavaScript
              challenges whilst working on professional designs.
              <br />
              <br />
              On the other hand, a front-end developer has one general
              responsibility: to ensure that website visitors can easily
              interact with the page. They do this through the combination of
              design, technology, and programming to code a website's
              appearance, as well as take care of debugging.
              <br />
              <br />
              Frontent mentor has 5 types of challenge. Newbie, Junior,
              Intermidate, Advance and Guro. Here free and free plus challenges
              can be started by anyone. All the starter codes including the
              assets and designs in JPG format are free to download.
              <br />
              <br />
              After the submission challenge solution, this will be reviewed by
              frontend mentor communites.
            </p>

            <p>
              Here is the collection of all Frontend Mentor challenge solutions
              by Kawsar Ahmed Fahad (faha1999). Here can find links to live
              demos and challenges on Frontend Mentor if you want to try them
              out. Happy coding :)
            </p>
          </div>

          <h4 style={{ fontWeight: 'bold' }}>My solutions</h4>
          <DividerLeft />

          <div className=" mt-5 frontEndBox">
            {frontendMentorData.map((data) => (
              <div className="row pt-3 pb-3">
                <div className="col-md-6">
                  <div className="radius-1 shadow-inner d-block ">
                    <div className="p-2">
                      <div className="shadow radius-1 imageContainer">
                        <img
                          className="img-fluid radius-1"
                          src={data.img}
                          alt={data.title}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 pt-3">
                  <h5>
                    Title: <span>{data.title}</span>
                  </h5>
                  <h5>
                    Difficulty: <span>{data.difficulty}</span>
                  </h5>
                  <h5>
                    Live:{' '}
                    <span>
                      <a target="_blank" rel="noreferrer" href={data.live}>
                        Preview
                      </a>
                    </span>
                  </h5>
                  <h5>
                    Repo:{' '}
                    <span>
                      <a target="_blank" rel="noreferrer" href={data.repo}>
                        Github
                      </a>
                    </span>
                  </h5>
                  <h5>
                    Tryout:{' '}
                    <span>
                      <a target="_blank" rel="noreferrer" href={data.tryout}>
                        Frontend Mentor
                      </a>
                    </span>
                  </h5>
                </div>
              </div>
            ))}
          </div>

          <ContentSocialIcon />
          <Comments />
          <ContentCommentForm />
        </div>
        {/* End blog post */}
      </section>
    </>
  );
};
