import React from 'react';
import { personalInfo1, personalInfo2 } from '../../../../data/aboutData';
import { motion } from 'framer-motion';

const Left = {
  offscreen: { x: -50, opacity: 0 },
  onscreen: {
    x: 0,
    y: 0,
    opacity: 1,
    transition: { duration: 0.7 },
  },
};

const Right = {
  offscreen: { x: 50, opacity: 0 },
  onscreen: {
    x: 0,
    y: 0,
    opacity: 1,
    transition: { duration: 0.7 },
  },
};

export const PersonalInfo = () => {
  return (
    <>
      <div className="row">
        <motion.div
          className="col-lg-6 mb-3 mb-lg-0"
          initial={'offscreen'}
          whileInView={'onscreen'}
          viewport={{ once: false, amount: 0.5 }}
          variants={Left}
        >
          <div className="text-center  p-2 radius-1 shadow-inner ">
            <div className="shadow radius-1">
              <ul className="text-center info-list font-med p-2 list-inline  mb-0">
                {personalInfo1.map((data) => (
                  <li key={data.title}>
                    <span className="inf">{data.title}</span>
                    <span className="value">{data.details}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </motion.div>

        <motion.div
          className="col-lg-6 mb-3 mb-lg-0"
          initial={'offscreen'}
          whileInView={'onscreen'}
          viewport={{ once: false, amount: 0.5 }}
          variants={Right}
        >
          <div className="text-center  p-2 radius-1 shadow-inner ">
            <div className="shadow radius-1">
              <ul className="text-center info-list font-med p-2 list-inline  mb-0">
                {personalInfo2.map((data) => (
                  <li key={data.title}>
                    <span className="inf">{data.title}</span>
                    <span className="value">{data.details}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
};
