import React from 'react';
import { DividerLeft } from '../../../partials/global partials/Hr';
import { Coding } from './partials/Coding';
import { Design } from './partials/Design';
import { Expediency } from './partials/Expediency';

export const Skills = () => {
  return (
    <>
      <div className="row mb-5 mt-5">
        <div className="col">
          <h6 className="font-weight-lg  mb-0 text-uppercase mb-2">
            Professional skills
          </h6>
          <DividerLeft />
        </div>
      </div>

      <Coding />
      <Design />
      <Expediency />
    </>
  );
};
