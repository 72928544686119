import React from 'react';
import { FaAngleLeft, FaAngleRight, FaEye, FaHome } from 'react-icons/fa';
import {
  DividerLeft,
  DividerMdCenter,
} from '../../../../partials/global partials/Hr';

import ManushoyeHome from '../../../../../assets/images/portfolio/Manushoye/Manushoye-home.webp';
import ManushoyeReg from '../../../../../assets/images/portfolio/Manushoye/Manushoye-post.webp';

export const Manushoye = () => {
  return (
    <>
      {/* Heading */}
      <div class="row mb-3">
        <div class="col">
          <h6
            class="font-weight-lg mb-0 text-uppercase mb-2"
            data-aos="fade-down"
          >
            Manushoye
          </h6>
          <DividerLeft />
        </div>
      </div>

      {/* Pagination */}
      <div className="row">
        <div className="col text-center">
          <ul className="list-inline project-nav mb-0">
            <li className="outer radius-2 list-inline-item mr-1">
              <a
                className="inner btn btn-round btn-icon"
                href="/portfolio/space-tourism-multi-page"
              >
                <span className="btn-icon-inner">
                  <FaAngleLeft />
                </span>
              </a>
            </li>
            <li className="outer radius-2 list-inline-item mr-1">
              <a className="inner btn btn-round btn-icon" href="/">
                <span className="btn-icon-inner">
                  <FaHome />
                </span>
              </a>
            </li>
            <li className="outer radius-2 list-inline-item mr-1">
              <a className="inner btn btn-round btn-icon" href="">
                <span className="btn-icon-inner">
                  <FaAngleRight />
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* projects intro */}
      <div class="row align-items-center justify-content-center mt-5">
        <div class="col-md-10">
          <h1 class="font-weight-xmd" data-aos="fade-down">
            Project <span class="orange-text-color">Manushoye</span>
          </h1>
          <p class="mb-0" data-aos="fade-up">
            Manushoye.com is a platform connecting help-seeking people with
            individual help providers by maintaining help seeker's privacy &
            dignity strictly. Here anyone can ask for any kinds of help as long
            as It's legal with a very easy procedure; at the same time, anyone
            at ease can find and provide assistance to help seeker from any
            locality he wants to. Throughout the process manushoye wouldn’t
            charge a single penny; means it completely free to use. <br />
            <small class="orange-text-color">
              Our goal is to bring harmony to society using ICT.
            </small>
          </p>
          <div class="w-100" data-aos="fade-down">
            <DividerMdCenter />
          </div>
          <ul class="list-inline font-med w-100 float-left">
            <li data-aos="fade-up">
              Job :
              <strong class="font-weight-xmd text-alt ms-2">
                Front-end Development
              </strong>
            </li>
            <li data-aos="fade-up" data-aos-duration="900">
              Live :
              <strong class="font-weight-xmd text-alt ms-2">
                <a href="https://www.manushoye.com/">Here</a>
              </strong>
            </li>
          </ul>
        </div>
      </div>

      {/* projects picture */}
      <div class="row align-items-center justify-content-center mt-5">
        <div class="col-md-10">
          <div class="mb-5 item" data-aos="fade-up">
            <a
              class="radius-1 shadow-inner hover-effect d-block"
              data-overlay="rgba(52,58,64,.6)"
              href="https://www.manushoye.com/"
            >
              <span class="hover-effect-container">
                <span class="hover-effect-icon ">
                  <span class="top-icon ">
                    <FaEye />
                  </span>
                </span>
              </span>
              <div class="p-2">
                <div class="shadow radius-1">
                  <img
                    class="img-fluid radius-1"
                    src={ManushoyeHome}
                    alt="Manushoye"
                  />
                </div>
              </div>
            </a>
          </div>
        </div>

        <div class="col-md-10">
          <div class="mb-5 item" data-aos="fade-up">
            <a
              class="radius-1 shadow-inner hover-effect d-block "
              data-overlay="rgba(52,58,64,.6)"
              href="https://www.manushoye.com/posts/register/"
            >
              <span class="hover-effect-container">
                <span class="hover-effect-icon ">
                  <span class="top-icon">
                    <FaEye />
                  </span>
                </span>
              </span>
              <div class="p-2">
                <div class="shadow radius-1">
                  <img
                    class="img-fluid radius-1"
                    src={ManushoyeReg}
                    alt="Manushoye"
                  />
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
