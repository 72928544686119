import React from 'react';
import {
  DividerLeft,
  DividerMdCenter,
} from '../../../partials/global partials/Hr';
import { FaAngleLeft, FaAngleRight, FaHome, FaEye } from 'react-icons/fa';

import EasyBankLandingPagePicture from '../../../../assets/images/portfolio/Easybank-landing-page.webp';

export const EasyBankLandingPage = () => {
  return (
    <>
      {/* Heading */}
      <div className="row mb-3">
        <div className="col">
          <h6 className="font-weight-lg mb-0 text-uppercase mb-2">
            Frontend Mentor challenge
          </h6>
          <DividerLeft />
        </div>
      </div>

      {/* Pagination */}
      <div className="row">
        <div className="col text-center">
          <ul className="list-inline project-nav mb-0">
            <li className="outer radius-2 list-inline-item mr-1">
              <a
                className="inner btn btn-round btn-icon disabled border-0"
                href=""
              >
                <span className="btn-icon-inner">
                  <FaAngleLeft />
                </span>
              </a>
            </li>
            <li className="outer radius-2 list-inline-item mr-1">
              <a className="inner btn btn-round btn-icon" href="/">
                <span className="btn-icon-inner">
                  <FaHome />
                </span>
              </a>
            </li>
            <li className="outer radius-2 list-inline-item mr-1">
              <a
                className="inner btn btn-round btn-icon"
                href="/portfolio/space-tourism-multi-page"
              >
                <span className="btn-icon-inner">
                  <FaAngleRight />
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* projects intro */}
      <div className="row align-items-center justify-content-center mt-5">
        <div className="col-md-10">
          <h1 className="font-weight-xmd">
            Challenge
            <span className="orange-text-color ms-2">
              Easybank landing page
            </span>
          </h1>
          <p className="mb-0">
            This challenge based on HTML, CSS & JS from Frontend Mentor.
          </p>
          <div className="w-100">
            <DividerMdCenter />
          </div>
          <ul className="list-inline font-med w-100 float-left">
            <li>
              Job :
              <strong className="font-weight-xmd text-alt ms-2">
                Development
              </strong>
            </li>
            <li>
              Live :
              <strong className="font-weight-xmd text-alt ms-2">
                <a href="https://faha1999.github.io/easybank-landing-page/">
                  Here
                </a>
              </strong>
            </li>
            <li>
              Solution :
              <strong className="font-weight-xmd text-alt ms-2">
                <a href="https://www.frontendmentor.io/solutions/easybank-landing-page-using-html-scss-and-javascript-0WlQCXWJ8">
                  View
                </a>
              </strong>
            </li>
          </ul>
        </div>
      </div>

      {/* projects picture */}
      <div className="row align-items-center justify-content-center mt-5">
        <div className="col-md-10">
          <div className="mb-5 item">
            <a
              className="radius-1 shadow-inner hover-effect d-block"
              data-overlay="rgba(52,58,64,.6)"
              href="https://faha1999.github.io/easybank-landing-page/"
            >
              <span className="hover-effect-container">
                <span className="hover-effect-icon ">
                  <span className="top-icon ">
                    <FaEye />
                  </span>
                </span>
              </span>
              <div className="p-2">
                <div className="shadow radius-1">
                  <img
                    className="img-fluid radius-1"
                    src={EasyBankLandingPagePicture}
                    alt="Easybank-landing-page"
                  />
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
