import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';
import { TestimonialData } from '../../../../../data/aboutData';

export const TestimonialsDetails = () => {
  return (
    <>
      <div className="row align-items-center">
        <Swiper
          className="mb-5"
          loop={true}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination]}
          spaceBetween={10}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
        >
          {TestimonialData.map((data) => (
            <SwiperSlide className="mb-5" key={data.index}>
              <div className="px-3 w-100 pt-5">
                <div className="card border-0 bg-dark">
                  <div className="card-body pt-0 shadow radius-2">
                    <div className="testimonial-image">
                      <div className="testimonial-image-border">
                        <img
                          className="rounded-circle"
                          src={data.img}
                          alt={data.name}
                        />
                      </div>
                    </div>
                    <p className=" mb-0 mt-2">{data.review}</p>
                  </div>
                  <div className="card-footer border-0 pt-0 px-5 pb-0 mt-3">
                    <span className="h6 font-med font-weight-xmd mb-0">
                      {data.name}
                    </span>
                    <small className="d-block text-muted">{data.title}</small>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};
