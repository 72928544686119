import React from 'react';
import { servicePart1, servicePart2 } from '../../../../../data/aboutData';
import { motion } from 'framer-motion';

const Left = {
  offscreen: { x: -50, opacity: 0 },
  onscreen: {
    x: 0,
    y: 0,
    opacity: 1,
    transition: { duration: 0.7 },
  },
};

const Right = {
  offscreen: { x: 50, opacity: 0 },
  onscreen: {
    x: 0,
    y: 0,
    opacity: 1,
    transition: { duration: 0.7 },
  },
};

export const ServicesDetails = () => {
  return (
    <>
      {/* First part */}
      <div className="row">
        {servicePart1.map((data) => (
          <motion.div
            className="col-md-4 mb-3 mb-lg-0 services-box"
            key={data.title}
            initial={'offscreen'}
            whileInView={'onscreen'}
            viewport={{ once: false, amount: 0.5 }}
            variants={Left}
          >
            <div className="service text-center">
              <div className="outer ui-icon-outer-lg  radius-2 m-auto">
                <div className="ui-icon ui-icon-lg  inner ">
                  <div className="ui-icon-inner ui-icon-inner-lg">
                    <img src={data.img} alt={data.title} />
                  </div>
                </div>
              </div>
              <h6 className="font-weight-xmd font-med mb-1 mt-3 orange-text-color">
                {data.title}
              </h6>
              <p className="mb-0">{data.details}</p>
            </div>
          </motion.div>
        ))}
      </div>
      {/* First part */}

      <div className="row">
        <div className="col-md-12 mt-5 mb-5 v-centred">
          <hr className="divider divider-md divider-center" />
        </div>
      </div>

      {/* 2nd part */}
      <div className="row">
        {servicePart2.map((data) => (
          <motion.div
            className="col-md-4 mb-3 mb-lg-0 services-box"
            key={data.title}
            initial={'offscreen'}
            whileInView={'onscreen'}
            viewport={{ once: false, amount: 0.5 }}
            variants={Right}
          >
            <div className="service text-center">
              <div className="outer ui-icon-outer-lg radius-2 m-auto">
                <div className="ui-icon ui-icon-lg inner ">
                  <div className="ui-icon-inner ui-icon-inner-lg">
                    <img src={data.img} alt={data.title} />
                  </div>
                </div>
              </div>
              <h6 className="font-weight-xmd font-med mb-1 mt-3 orange-text-color">
                {data.title}
              </h6>
              <p className="mb-0">{data.details}</p>
            </div>
          </motion.div>
        ))}
      </div>
      {/* 2nd part */}
    </>
  );
};
