import React from 'react';

export const Map = () => {
  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="item">
            <a
              className="radius-1 shadow-inner d-block "
              href="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d3579.032511188611!2d91.92026804787469!3d24.904908868786748!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1641573894642!5m2!1sen!2sbd"
            >
              <div className="p-2">
                <div className="shadow radius-1">
                  <iframe
                    title="map"
                    className="radius-1 opacity-75 m-0 p-0"
                    src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d3579.032511188611!2d91.92026804787469!3d24.904908868786748!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1641573894642!5m2!1sen!2sbd"
                    width="600"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen
                    loading="lazy"
                  ></iframe>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
