import React from 'react';
import { DividerLeft } from '../../../../../partials/global partials/Hr';
import Faha1999 from '../../../../../../assets/images/faha1999.webp';
import { BsChatFill } from 'react-icons/bs';

export const Comments = () => {
  return (
    <>
      <div className="row mb-5">
        <div className="col">
          <h6 className="font-weight-lg mb-0 text-uppercase mb-2">
            Comments(4)
          </h6>
          <DividerLeft />
        </div>
      </div>

      {/* Start Comments */}
      <div className="row justify-content-center">
        <div className="col-md-12">
          <ul className="comments-list list-inline mb-0">
            <li className="list-inline-item me-0">
              <div className="comment">
                <div className="media align-items-center mb-3">
                  <div className="radius-3 shadow-inner">
                    <div className="p-1">
                      <div className="shadow radius-3 p-1">
                        <img
                          src={Faha1999}
                          className="avatar-sm rounded-circle img-fluid"
                          alt="faha1999"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="ms-2 media-body">
                    <a
                      className="d-block orange-text-color mb-0 font-weight-md font-sm"
                      href="#"
                    >
                      Benaissa Ghrib
                    </a>
                    <small className="d-block text-alt font-xsm">
                      10th January 2019
                    </small>
                  </div>
                </div>
                <p>
                  A typical lunch meal begins with a series of hot and cold
                  salads, followed by a tagine or Dwaz. Often, for a formal
                  meal, a lamb or chicken dish is next, or couscous topped with
                  meat and vegetables. Moroccans either eat with fork, knife and
                  spoon or with their hands using bread as a utensil depending
                  on the dish served. The consumption of pork and alcohol is
                  uncommon due to religious restrictions
                </p>
                <a href="#comment">
                  <span className="me-1">
                    <BsChatFill />
                  </span>
                  Reply
                </a>
              </div>
            </li>
            <li className="list-inline-item">
              <div className="comment">
                <div className="media align-items-center mb-3">
                  <div className="radius-3 shadow-inner">
                    <div className="p-1">
                      <div className="shadow radius-3 p-1">
                        <img
                          src={Faha1999}
                          alt="faha1999"
                          className="avatar-sm rounded-circle img-fluid "
                        />
                      </div>
                    </div>
                  </div>
                  <div className="ms-2 media-body">
                    <a
                      className="d-block orange-text-color mb-0 font-weight-md font-sm"
                      href="#"
                    >
                      Zakaria Kalal
                    </a>
                    <small className="d-block text-alt font-xsm">
                      10th January 2019
                    </small>
                  </div>
                </div>
                <p>
                  Moroccan cuisine is influenced by Morocco's interactions and
                  exchanges with other cultures and nations over the
                  centuries.[1] Moroccan cuisine is typically a mix of Berber,
                  Arabic, Andalusian, and Mediterranean cuisines with slight
                  European and sub-Saharan influences.
                </p>
                <a href="#comment">
                  <span className="me-1">
                    <BsChatFill />
                  </span>
                  Reply
                </a>
              </div>
              <div className="comment">
                <div className="media align-items-center mb-3">
                  <div className="radius-3 shadow-inner">
                    <div className="p-1">
                      <div className="shadow radius-3 p-1">
                        <img
                          src={Faha1999}
                          alt="faha1999"
                          className="avatar-sm rounded-circle img-fluid "
                        />
                      </div>
                    </div>
                  </div>
                  <div className="ms-2 media-body">
                    <a
                      className="d-block orange-text-color mb-0 font-weight-md font-sm"
                      href="#"
                    >
                      Youssef Ait
                    </a>
                    <small className="d-block text-alt font-xsm">
                      10th January 2019
                    </small>
                  </div>
                </div>
                <p>
                  Salads include both raw and cooked vegetables, served either
                  hot or cold Cold salads include zaalouk, an aubergine and
                  tomato mixture, and taktouka (a mixture of tomatoes, smoked
                  green peppers, garlic and spices) characteristic of the cities
                  of Taza and Fes, in the Atlas Another cold salad is called
                  Bakoula, or Khoubiza. It consists of braised mallow leaves,
                  but can also be made with spinach or arugula, with parsley,
                  cilantro, lemon, olive oil and olives.
                </p>
                <a href="#comment">
                  <span className="me-1">
                    <BsChatFill />
                  </span>
                  Reply
                </a>
              </div>
            </li>
            <li className="list-inline-item me-0">
              <div className="comment">
                <div className="media align-items-center mb-3">
                  <div className="radius-3 shadow-inner">
                    <div className="p-1">
                      <div className="shadow radius-3 p-1">
                        <img
                          src={Faha1999}
                          alt="faha1999"
                          className="avatar-sm rounded-circle img-fluid "
                        />
                      </div>
                    </div>
                  </div>
                  <div className="ms-2 media-body">
                    <a
                      className="d-block orange-text-color mb-0 font-weight-md font-sm"
                      href="#"
                    >
                      Soufian Kabbaj
                    </a>
                    <small className="d-block text-alt font-xsm ">
                      10th January 2019
                    </small>
                  </div>
                </div>
                <p>
                  Melbourne is the coastal capital of the southeastern
                  Australian state of Victoria. At the city's centre is the
                  modern Federation Square development, with plazas, bars, and
                  restaurants by the Yarra River. In the Southbank area, the
                  Melbourne Arts Precinct is the site of Arts Centre Melbourne
                  and the National Gallery of Victoria, with Australian and
                  indigenous art.
                </p>
                <a href="#comment">
                  <span className="me-1">
                    <BsChatFill />
                  </span>
                  Reply
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
