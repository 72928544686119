import React from 'react';
import { Email } from './partials/Email';
import { Info } from './partials/Info';
import { Map } from './partials/Map';

export const ContactDetails = () => {
  return (
    <>
      <Map />
      <Info />
      <Email />
    </>
  );
};
