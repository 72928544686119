import React from 'react';
import { DividerLeft } from '../../../../partials/global partials/Hr';
import PictureOne from '../../../../../assets/images/blog/1.jpg';
import PictureTwo from '../../../../../assets/images/blog/2.jpg';
import { ContentSocialIcon } from '../../../../partials/global partials/ContentSocialIcon';
import { ContentCommentForm } from '../../../../partials/global partials/ContentCommentForm';
import { Comments } from './partials/Comments.jsx';

export const ContentOne = () => {
  return (
    <>
      <section className="shadow bg-dark radius-2 p-5 mb-5 BlogContent">
        <div className="row mb-5">
          <div className="col">
            <h6 className="font-weight-lg text-uppercase mb-2">The blog</h6>
            <DividerLeft />
          </div>
        </div>

        {/* Start blog post */}
        <div className="row align-items-center justify-content-center">
          <div className="col-md-12">
            <div className="mb-4 item">
              <div className="radius-1 shadow-inner d-block ">
                <div className="p-2">
                  <div className="shadow radius-1">
                    <img
                      className="img-fluid radius-1"
                      src={PictureOne}
                      alt="Which design layout solution should you use ?"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <h1 className="h5 font-weight-md d-inline-block">
                Which design layout solution should you use ?
              </h1>
              <span className="d-inline-block shadow-inner orange-text-color radius-1 font-xsm  mb-3 mb-lg-0  px-3">
                13 Dec
              </span>
            </div>

            <ul className="list-inline font-xsm ">
              <li className="list-inline-item">
                <span className=" fas fa-user mr-1 "></span>
                <span>
                  Post by
                  <a href="#"> Kawsar Ahmed Fahad</a>
                </span>
              </li>
              <li className="list-inline-item">
                <span className=" fas fa-tag "></span>
                <span>
                  Tagged in
                  <a href="#"> Web Design</a>
                </span>
              </li>
              <li className="list-inline-item">
                <span className=" fas fa-comment mr-1"></span>
                <span>
                  Comments
                  <a href="#"> (2)</a>
                </span>
              </li>
            </ul>

            <hr className="mt-3 mb-3" />

            <p>
              Spices are used extensively in Moroccan food. Although some spices
              have been imported to Morocco through the Arabs for thousands of
              years, many ingredients—like saffron from Talaouine, mint and
              olives from Meknes, and oranges and lemons from Fes—are
              home-grown, and are being exported internationally. Common spices
              include cinnamon, cumin, turmeric, ginger, paprika, coriander,
              saffron, mace, cloves, fennel, anise, nutmeg, cayenne pepper,
              fenugreek, caraway, black pepper and sesame seeds. Twenty-seven
              spices are combined for the famous <a href="#">Moroccan</a> spice
              mixture ras el hanout.Common herbs in Moroccan cuisine include
              mint, parsley, coriander, oregano, peppermint, marjoram, verbena,
              sage and bay laurel.
            </p>
            <p>
              Morocco produces a large range of Mediterranean fruits, vegetables
              and even some tropical ones. Common meats include beef, goat,
              mutton and lamb, chicken and seafood, which serve as a base for
              the cuisine. Characteristic flavorings include lemon pickle, argan
              oil, cold-pressed, unrefined olive oil and dried fruits. As in
              <em>Mediterranean cuisine</em> in general, the staple
              <a href="#"> ingredients</a> include wheat, used for bread and
              couscous, and olive oil; the third Mediterranean staple, the
              grape, is eaten as a dessert, though a certain amount of wine is
              made in the country
            </p>

            <h4 className="h5 font-weight-md">Morrocan Couscous</h4>

            <p>
              The main Moroccan dish most people are familiar with is couscous,
              the old national delicacy. Beef is the most commonly eaten red
              meat in Morocco, <a href="#">usually</a> eaten in a tagine with a
              wide selection of vegetables. Chicken is also very commonly used
              in tagines, or roasted.Lamb is also heavily consumed, and since
              Moroccan sheep breeds store most of their fat in their tails,
              Moroccan lamb does not have the pungent flavour that Western lamb
              and mutton have.
            </p>

            <figure className="text-center mt-5 mb-5 w-75 mx-auto item">
              <div className="radius-1 shadow-inner d-block ">
                <div className="p-2">
                  <div className="shadow radius-1">
                    <img
                      className="img-fluid radius-1"
                      src={PictureTwo}
                      alt="Which design layout solution should you use ?"
                    />
                  </div>
                </div>
              </div>
              <figcaption className="small mt-2 text-muted">
                Moroccan Couscous with vegetables
              </figcaption>
            </figure>

            <h5 className="h6 font-weight-md">Moroccan Couscous</h5>
            <p>
              Usually, seasonal fruits rather than <a href="#">cooked</a>{' '}
              desserts are served at the close of a meal. A common dessert is
              kaab el ghzal ("gazelle's horns"), a pastry stuffed with almond
              paste and topped with sugar. Another is "Halwa chebakia",
              pretzel-shaped dough deep-fried, soaked in honey and sprinkled
              with sesame seeds; it is eaten during the month of
              <a href="#"> Ramadan</a>. Coconut fudge cakes, 'Zucre Coco', are
              popular also.
            </p>
            <ul className="mb-0 font-sm ">
              <li>The Art of Moroccan Cuisine</li>
              <li>An Introduction to Mediterranean Cuisine</li>
              <li>
                <em>
                  Moroccan cuisine is influenced by Morocco's interactions
                </em>
              </li>
              <li>Mediterranean cuisine</li>
            </ul>
          </div>

          <ContentSocialIcon />
          <Comments />
          <ContentCommentForm />
        </div>
        {/* End blog post */}
      </section>
    </>
  );
};
