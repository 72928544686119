import React from 'react';
import { Description } from './dist/aboutMe/Description';
import { PersonalInfo } from './dist/aboutMe/PersonalInfo';
import { Skills } from './dist/skills/Skills';
import { Education } from './dist/education/Education';
import { Experience } from './dist/experience/Experience';
import { Services } from './dist/services/Services';
import { Testimonials } from './dist/Testimonials/Testimonials';
import { DividerLeft } from '../partials/global partials/Hr';
import { motion } from 'framer-motion';

const up = {
  offscreen: { y: -50, opacity: 0 },
  onscreen: {
    x: 0,
    y: 0,
    opacity: 1,
    transition: { duration: 0.7 },
  },
};

export const About = () => {
  return (
    <section className="shadow bg-dark radius-2 p-5 mb-5" id="about">
      <div className="row mb-5">
        <div className="col">
          <h6 className="font-weight-lg  mb-0 text-uppercase mb-2">About me</h6>
          <DividerLeft />
        </div>
      </div>
      <motion.div
        className="row mb-2"
        initial={'offscreen'}
        whileInView={'onscreen'}
        viewport={{ once: false, amount: 0.5 }}
        variants={up}
      >
        <div className="col-lg-12">
          <h1 className="font-weight-xmd mb-0">Kawsar Ahmed Fahad</h1>
          <h6 className="h5 text-alt mt-2 orange-text-color">
            Front-end Developer
          </h6>
        </div>
      </motion.div>

      <Description />
      <PersonalInfo />
      <Skills />
      {/* <Education /> */}
      <Experience />
      <Services />
      <Testimonials />
    </section>
  );
};
