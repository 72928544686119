import React, { useEffect, useState } from 'react';
import { portfolioData } from '../../../data/portfolioData';
import { AnimatePresence, motion } from 'framer-motion';
import { FaEye } from 'react-icons/fa';
import { LightBoxModal } from './partials/LightBoxModal';
import { DividerMdCenter } from '../../partials/global partials/Hr';

const TagButton = ({ name, handleSetTag, tagActive }) => {
  return (
    <span
      className={`category ${tagActive ? 'active' : null} list inner radius-2`}
      onClick={() => handleSetTag(name)}
    >
      {name.toUpperCase()}
    </span>
  );
};

export const PortfolioFilter = () => {
  const [category, setCategory] = useState('all');
  const [filteredImages, setFilteredImages] = useState([]);

  useEffect(() => {
    category === 'all'
      ? setFilteredImages(portfolioData)
      : setFilteredImages(
          portfolioData.filter((image) => image.category === category),
        );
  }, [category]);

  const [clickedImg, setClickedImg] = useState(null);

  const handleClick = (data) => {
    setClickedImg(data.src);
  };

  return (
    <>
      <div className="row text-center">
        <div className="col">
          <ul className="filter mb-0 ps-0">
            <li className="radius-2 mb-3 mb-md-0">
              <TagButton
                name="all"
                tagActive={category === 'all' ? true : false}
                handleSetTag={setCategory}
              >
                all
              </TagButton>
            </li>
            <li className=" radius-2 mb-3 mb-md-0">
              <TagButton
                name="web"
                tagActive={category === 'web' ? true : false}
                handleSetTag={setCategory}
              >
                Web design
              </TagButton>
            </li>
            <li className=" radius-2 mb-3 mb-md-0">
              <TagButton
                name="graphic"
                tagActive={category === 'graphic' ? true : false}
                handleSetTag={setCategory}
              >
                Graphic design
              </TagButton>
            </li>
            <li className=" radius-2 mb-3 mb-md-0">
              <TagButton
                name="branding"
                tagActive={category === 'branding' ? true : false}
                handleSetTag={setCategory}
              >
                Branding
              </TagButton>
            </li>
          </ul>
        </div>
      </div>

      {/* hr */}
      <div className="row">
        <DividerMdCenter />
      </div>

      {/* data */}
      <motion.div className="row product">
        <AnimatePresence>
          {filteredImages.map((data) => (
            <motion.div
              layout
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              className="col-lg-4 mb-5 itemBox"
              key={data.id}
            >
              <div className="item">
                <a
                  className="radius-1 shadow-inner hover-effect d-block"
                  data-overlay="rgba(52,58,64,.6)"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => handleClick(data)}
                  href={data.url}
                >
                  <span className="hover-effect-container">
                    <span className="hover-effect-icon ">
                      <span className="top-icon">
                        <FaEye />
                      </span>
                    </span>
                  </span>
                  <div className="p-2">
                    <div className="shadow radius-1">
                      <img
                        className=" radius-1"
                        alt={data.alt}
                        src={data.src}
                        width="100%"
                        height="100%"
                      />
                    </div>
                  </div>
                </a>
              </div>
            </motion.div>
          ))}
        </AnimatePresence>

        <div>
          {clickedImg && (
            <LightBoxModal
              clickedImg={clickedImg}
              setClickedImg={setClickedImg}
            />
          )}
        </div>
      </motion.div>
    </>
  );
};
