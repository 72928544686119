import easyBank from '../assets/images/portfolio/Easybank-landing-page.webp';
import SpaceTourism from '../assets/images/portfolio/Space-tourism-multi-page.webp';
import ficcNotePad from '../assets/images/portfolio/ficc-note-pad.webp';
import ManushoyeHome from '../assets/images/portfolio/Manushoye/Manushoye-home.webp';
import kisobBook1 from '../assets/images/portfolio/kisob-book-1.webp';
import kisobBook2 from '../assets/images/portfolio/kisob-book-2.webp';
import kisobBook3 from '../assets/images/portfolio/kisob-book-3.webp';
import kisobBook4 from '../assets/images/portfolio/kisob-book-4.webp';
import kisobBook5 from '../assets/images/portfolio/kisob-book-5.webp';
import kisobBook6 from '../assets/images/portfolio/kisob-book-6.webp';
import kisobBook7 from '../assets/images/portfolio/kisob-book-7.webp';
import kisobBook8 from '../assets/images/portfolio/kisob-book-8.webp';
import kisobBook9 from '../assets/images/portfolio/kisob-book-9.webp';
import kisobBook10 from '../assets/images/portfolio/kisob-book-10.webp';
import aloBG from '../assets/images/portfolio/alo-business-group.webp';
import ibc from '../assets/images/portfolio/islamic-book-center.webp';
import ficcNotePad2 from '../assets/images/portfolio/ficc-note-pad-2.webp';
import kisob from '../assets/images/portfolio/kisob.webp';
import labibAf from '../assets/images/portfolio/labib-agro-firm.webp';
import aloMS from '../assets/images/portfolio/alo-mega-shop.webp';
import ibcCover from '../assets/images/portfolio/islamic-book-center-cover-photo.webp';
// import easyBank from '../assets/images/portfolio/Easybank';

export const portfolioData = [
  {
    id: 1,
    category: 'graphic',
    alt: 'kisob book',
    src: kisobBook1,
  },
  {
    id: 2,
    category: 'web',
    alt: 'easy Bank',
    url: '/portfolio/easybank-landing-page',
    src: easyBank,
  },
  {
    id: 3,
    category: 'graphic',
    alt: 'kisob book',
    src: kisobBook2,
  },
  {
    id: 4,
    category: 'branding',
    alt: 'alo-business-group',
    src: aloBG,
  },

  {
    id: 6,
    category: 'graphic',
    alt: 'kisob book',
    src: kisobBook3,
  },
  {
    id: 7,
    category: 'graphic',
    alt: 'kisob book',
    src: kisobBook4,
  },
  {
    id: 8,
    category: 'graphic',
    alt: 'kisob book',
    src: kisobBook9,
  },
  {
    id: 9,
    category: 'graphic',
    alt: 'kisob book',
    src: kisobBook10,
  },
  {
    id: 10,
    category: 'branding',
    alt: 'kisob',
    src: kisob,
  },

  {
    id: 12,
    category: 'web',
    alt: 'Manushoye',
    url: '/portfolio/manushoye',
    src: ManushoyeHome,
  },
  {
    id: 13,
    category: 'branding',
    alt: 'labib agro firm',
    src: labibAf,
  },
  {
    id: 14,
    category: 'branding',
    alt: 'alo mega shop',
    src: aloMS,
  },
  {
    id: 15,
    category: 'graphic',
    alt: 'kisob book',
    src: kisobBook5,
  },
  {
    id: 16,
    category: 'graphic',
    alt: 'kisob book',
    src: kisobBook6,
  },
  {
    id: 17,
    category: 'web',
    alt: 'Space Tourism',
    url: '/portfolio/space-tourism-multi-page',
    src: SpaceTourism,
  },
  {
    id: 18,
    category: 'graphic',
    alt: 'kisob book',
    src: kisobBook7,
  },
  {
    id: 19,
    category: 'graphic',
    alt: 'kisob book',
    src: kisobBook8,
  },
  {
    id: 20,
    category: 'graphic',
    alt: 'ficc Note Pad',
    src: ficcNotePad,
  },

  {
    id: 21,
    category: 'graphic',
    alt: 'ficc Note Pad 2',
    src: ficcNotePad2,
  },
  {
    id: 5,
    category: 'branding',
    alt: 'islamic book center',
    src: ibc,
  },
  {
    id: 11,
    category: 'branding',
    alt: 'islamic book center cover',
    src: ibcCover,
  },
];
