import React from 'react';
import { DividerLeft } from './Hr';

export const ContentCommentForm = () => {
  return (
    <>
      <div className="row mb-5 mt-5">
        <div className="col">
          <h6 className="font-weight-lg  mb-0 text-uppercase mb-2">
            Leave a comment
          </h6>
          <DividerLeft />
        </div>
      </div>

      <div id="comment" className="row justify-content-center">
        <div className="col-md-12">
          <form action="#">
            <div className="row">
              <div className="col-12">
                <div className="outer radius-2 mb-3">
                  <textarea
                    className="radius-2 inner form-control  resize-n "
                    rows="6"
                    name="description"
                    placeholder="Type your comment ..."
                    required
                  ></textarea>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="outer radius-2 mb-3">
                  <input
                    className="inner form-control  h-100 "
                    name="name"
                    placeholder="Name"
                    type="text"
                    required
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="outer radius-2 mb-3">
                  <input
                    className="inner form-control  h-100 "
                    name="email"
                    placeholder="Email"
                    type="email"
                    required
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="outer radius-2 d-inline-block text-center">
                  <a
                    className="inner radius-2 d-block py-2 px-4 font-sm font-weight-md"
                    href="blog1.html"
                  >
                    Post comment
                  </a>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
