import React from 'react';
import { motion } from 'framer-motion';

const Left = {
  offscreen: { x: -50, opacity: 0 },
  onscreen: {
    x: 0,
    y: 0,
    opacity: 1,
    transition: { duration: 0.7 },
  },
};

const Right = {
  offscreen: { x: 50, opacity: 0 },
  onscreen: {
    x: 0,
    y: 0,
    opacity: 1,
    transition: { duration: 0.7 },
  },
};

export const Description = () => {
  return (
    <>
      <div className="row mb-2">
        <motion.div
          className="col-md-6"
          initial={'offscreen'}
          whileInView={'onscreen'}
          viewport={{ once: false, amount: 0.5 }}
          variants={Left}
        >
          <p>
            People find me to be an upbeat, self-motivated team player with
            excellent communication & organizational skills. I am able to
            complete multiple tasks successfully with favorable results despite
            deadline pressure. I have not only headed any project but also
            ensured its success by demonstrating leadership abilities.I keep a
            keen view to reality of life thus approach people with positive
            mind, attitude and always prove to be helpful with honest efforts
            and true dedication.
          </p>
        </motion.div>
        <motion.div
          className="col-md-6"
          initial={'offscreen'}
          whileInView={'onscreen'}
          viewport={{ once: false, amount: 0.5 }}
          variants={Right}
        >
          <p>
            Besides that I always feel very joyous and fortunate to meet and
            greet people belonging to different backgrounds and cultures. As
            such meetings are always important because they prove to be
            beneficial in future also it makes things easy to cope whether one
            works or studies in his own country or outside the country. I
            believe{' '}
            <strong className="orange-text-color">
              “Never stop dreaming because my success is hiding inside my
              dream.’’
            </strong>
          </p>
        </motion.div>
      </div>
    </>
  );
};
