import React from 'react';

import { ImFacebook, ImVimeo2 } from 'react-icons/im';
import {
  AiOutlineGoogle,
  AiFillLinkedin,
  AiFillGithub,
  AiOutlineTwitter,
  AiOutlineInstagram,
  AiFillYoutube,
} from 'react-icons/ai';

export const ContentSocialIcon = () => {
  return (
    <>
      <div className="col-xl-9 col-lg-8 col-md-10">
        <hr className="my-5" />

        <ul className="list-inline  mb-0  text-center">
          <li className="list-inline-item outer radius-2 me-3 mb-2 mb-lg-0">
            <a className="inner btn btn-round btn-icon" href="#">
              <span className="btn-icon-inner">
                <ImFacebook />
              </span>
            </a>
          </li>
          <li className="list-inline-item me-3 outer radius-2 mb-2 mb-lg-0">
            <a className="inner btn btn-round btn-icon" href="#">
              <span className="btn-icon-inner">
                <AiOutlineGoogle />
              </span>
            </a>
          </li>
          <li className="list-inline-item me-3 mx-0 outer radius-2 mb-2 mb-lg-0">
            <a className="inner btn btn-round btn-icon" href="#">
              <span className="btn-icon-inner">
                <AiOutlineTwitter />
              </span>
            </a>
          </li>
          <li className="list-inline-item outer radius-2 me-3 mb-2 mb-lg-0">
            <a className="inner btn btn-round btn-icon" href="#">
              <span className="btn-icon-inner">
                <AiOutlineInstagram />
              </span>
            </a>
          </li>
          <li className="list-inline-item me-3 outer radius-2 mb-2 mb-lg-0">
            <a className="inner btn btn-round btn-icon" href="#">
              <span className="btn-icon-inner">
                <AiFillLinkedin />
              </span>
            </a>
          </li>
          <li className="list-inline-item me-3 mx-0 outer radius-2 mb-2 mb-lg-0">
            <a className="inner btn btn-round btn-icon" href="#">
              <span className="btn-icon-inner">
                <AiFillGithub />
              </span>
            </a>
          </li>
          <li className="list-inline-item me-3 outer radius-2 mb-2 mb-lg-0">
            <a className="inner btn btn-round btn-icon" href="#">
              <span className="btn-icon-inner">
                <ImVimeo2 />
              </span>
            </a>
          </li>
          <li className="list-inline-item mx-0 outer radius-2 mb-2 mb-lg-0">
            <a className="inner btn btn-round btn-icon" href="#">
              <span className="btn-icon-inner">
                <AiFillYoutube />
              </span>
            </a>
          </li>
        </ul>

        <hr className="my-5" />
      </div>
    </>
  );
};
