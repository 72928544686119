import React from 'react';

import homeIcon from '../../../../assets/images/icon/Dark theme/home.gif';
import blogIcon from '../../../../assets/images/icon/Dark theme/blog.gif';
import searchIcon from '../../../../assets/images/icon/Dark theme/search.gif';

export const MobileNav = () => {
  return (
    <>
      {' '}
      <header className="mobileMenu">
        <nav className="shadow bg-dark radius-2">
          <ul>
            <li>
              <a className="link inner radius-2" href="/">
                <div className="align-items-center">
                  <div className="text-center gap-3">
                    <div className="icon">
                      <img src={homeIcon} alt="home" />
                    </div>
                  </div>
                </div>
              </a>
            </li>

            <li>
              <a className="link inner radius-2" href="/blog">
                <div className="align-items-center">
                  <div className="text-center gap-3">
                    <div className="icon">
                      <img src={blogIcon} alt="home" />
                    </div>
                  </div>
                </div>
              </a>
            </li>

            <li>
              <a className="link inner radius-2">
                <div className="align-items-center">
                  <div className="text-center gap-3">
                    <div className="icon">
                      <img src={searchIcon} alt="search" />
                    </div>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
};
