import { ImFacebook } from 'react-icons/im';
import { AiOutlineGoogle, AiFillLinkedin, AiFillGithub } from 'react-icons/ai';

export const homeData = [
  {
    url: 'https://www.facebook.com/faha1999/',
    icon: <ImFacebook />,
    id: 'facebook',
  },
  {
    url: 'https://g.dev/faha',
    icon: <AiOutlineGoogle />,
    id: 'google',
  },
  {
    url: 'https://www.linkedin.com/feed/',
    icon: <AiFillLinkedin />,
    id: 'linkedin',
  },
  {
    url: 'https://github.com/faha1999',
    icon: <AiFillGithub />,
    id: 'github',
  },
];
