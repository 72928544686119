import React from 'react';
import { DividerLeft } from '../../../partials/global partials/Hr';
import { TestimonialsDetails } from './partials/TestimonialsDetails';

export const Testimonials = () => {
  return (
    <>
      <div className="row mb-5 mt-5">
        <div className="col">
          <h6 className="font-weight-lg  mb-0 text-uppercase mb-2">
            Testimonials
          </h6>
          <DividerLeft />
        </div>
      </div>

      <TestimonialsDetails />
    </>
  );
};
