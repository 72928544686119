import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import faha1999 from '../../../assets/images/faha1999.webp';
import homeIcon from '../../../assets/images/icon/Dark theme/home.gif';
import blogIcon from '../../../assets/images/icon/Dark theme/blog.gif';
import searchIcon from '../../../assets/images/icon/Dark theme/search.gif';

import { BsTextIndentRight } from 'react-icons/bs';
import { BsTextIndentLeft } from 'react-icons/bs';
import { MobileNav } from './Layout/MobileNav';
import { ContentOne } from './contents/Blog1/ContentOne';
import { ContentTwo } from './contents/Blog2/ContentTwo';
import { ContentThree } from './contents/Blog3/ContentThree';
import { Route, Routes } from 'react-router-dom';

export const Layout = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => setIsOpen(!isOpen);

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: 'auto',
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <>
      <MobileNav />
      <div className="mainContainer container-fluid">
        <motion.header
          animate={{
            width: isOpen ? '200px' : '55px',
            height: isOpen ? 'auto' : '22rem',

            transition: {
              duration: 0.5,
              type: 'spring',
              damping: 10,
            },
          }}
          className="sidebar py-5 my-4 shadow bg-dark radius-2"
        >
          <div
            className="bars"
            style={{
              paddingRight: isOpen ? '' : '2.4rem',
            }}
          >
            {isOpen ? (
              <BsTextIndentRight onClick={toggle} />
            ) : (
              <BsTextIndentLeft style={{ color: '#e57525' }} onClick={toggle} />
            )}
          </div>

          <div
            className={isOpen ? 'top-section' : ' pt-5'}
            style={{
              width: isOpen ? '' : '45px',
              height: isOpen ? '' : '45px',
              margin: isOpen ? '' : '0 auto',
              padding: isOpen ? '' : '0',
              paddingBottom: isOpen ? '' : '3rem',
            }}
          >
            <AnimatePresence>
              <>
                <motion.div
                  className="profile-image"
                  style={{
                    width: isOpen ? '' : '45px',
                    height: isOpen ? '' : '45px',
                    paddingBottom: isOpen ? '' : '2rem',
                  }}
                >
                  <div
                    className="profile-image-border"
                    style={{
                      boxShadow: isOpen
                        ? ''
                        : 'inset 1px 1px 1px #202125, inset -1px -1px 1px #353535',
                      width: isOpen ? '' : '25px',
                      height: isOpen ? '' : '25px',
                    }}
                  >
                    <motion.img
                      style={{
                        width: isOpen ? '' : '35px',
                        height: isOpen ? '' : '35px',
                        marginTop: isOpen ? '' : '-14px',
                        marginLeft: isOpen ? '' : '-9.2px',
                      }}
                      className="rounded-circle"
                      src={faha1999}
                      alt="Kawsar Ahmed Fahad"
                    />
                  </div>
                </motion.div>

                {isOpen && (
                  <motion.div
                    variants={showAnimation}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                    className="mt-4 text-center"
                  >
                    <h3 className="font-weight-xmd mb-0 orange-text-color font-med">
                      Kawsar Ahmed Fahad
                    </h3>
                    <h6 className="text-alt mt-2 mb-0 font-xsm">
                      Front-end Developer
                    </h6>
                  </motion.div>
                )}
              </>
            </AnimatePresence>
          </div>

          <nav className="sidebarMenu main-nav clearfix tabbed">
            <ul className={isOpen ? 'ps-3 pe-3' : 'ps-0'}>
              <li className={isOpen ? 'outer radius-2 mb-3' : 'radius-2 mb-3'}>
                <a href="/" className="link inner radius-2">
                  <div className="media align-items-center">
                    <div className="media-body text-center gap-3">
                      <div className="icon">
                        <img src={homeIcon} alt="home" />
                      </div>

                      <AnimatePresence>
                        {isOpen && (
                          <motion.div
                            variants={showAnimation}
                            initial="hidden"
                            animate="show"
                            exit="hidden"
                            className="text font-weight-xmd font-sm text-uppercase"
                          >
                            Home
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                  </div>
                </a>
              </li>

              <li className={isOpen ? 'outer radius-2 mb-3' : 'radius-2 mb-3'}>
                <a href="/blog" className="link inner radius-2">
                  <div className="media align-items-center">
                    <div className="media-body text-center gap-3">
                      <div className="icon">
                        <img src={blogIcon} alt="home" />
                      </div>

                      <AnimatePresence>
                        {isOpen && (
                          <motion.div
                            variants={showAnimation}
                            initial="hidden"
                            animate="show"
                            exit="hidden"
                            className="text font-weight-xmd font-sm text-uppercase"
                          >
                            Blog
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                  </div>
                </a>
              </li>

              <li className={isOpen ? 'outer radius-2 mb-3' : 'radius-2 mb-3'}>
                <form
                  className="header-search-form position-relative"
                  action="#"
                >
                  <AnimatePresence>
                    {isOpen && (
                      <>
                        <motion.input type="text" placeholder="Search" />
                        <motion.input type="submit" />
                      </>
                    )}
                  </AnimatePresence>

                  <span className="search-form-icon">
                    <img
                      style={{ right: isOpen ? '' : '10px' }}
                      src={searchIcon}
                      alt="search"
                    />
                  </span>
                </form>
              </li>
            </ul>
          </nav>

          {isOpen && (
            <div className="text-center">
              <p className="font-med mb-2 text-muted orange-text-color">
                &copy; faha1999
              </p>
            </div>
          )}
        </motion.header>

        <motion.main
          className="w-100 mt-4"
          animate={{
            paddingLeft: isOpen ? '210px' : '70px',

            transition: {
              duration: 0.5,
              type: 'spring',
              damping: 10,
            },
          }}
        >
          <Routes>
            <Route path="contentOne" element={<ContentOne />} />
            <Route path="contentTwo" element={<ContentTwo />} />
            <Route path="contentThree" element={<ContentThree />} />
          </Routes>
        </motion.main>
      </div>
    </>
  );
};
