import React from 'react';
import mapIcon from '../../../../assets/images/icon/Dark theme/map.gif';
import emailIcon from '../../../../assets/images/icon/Dark theme/email.gif';
import codeIcon from '../../../../assets/images/icon/Dark theme/code.gif';
import loveIcon from '../../../../assets/images/icon/Dark theme/love.gif';
import { DividerLeft } from '../../../partials/global partials/Hr';

export const Info = () => {
  return (
    <>
      <div className="row mb-5 mt-5">
        <div className="col">
          <h6 className="font-weight-lg  mb-0 text-uppercase mb-2">
            Contact Info
          </h6>
          <DividerLeft />
        </div>
      </div>

      {/* Details */}
      <div className="row info-before position-relative">
        <div className="col-md-12 mb-5">
          <div className="w-75 m-auto ">
            <div className="radius-5">
              <div className="shadow-inner  ui-icon ui-icon-xl ui-icon-lg-2 ">
                <div className="ui-icon-inner">
                  <img
                    src={mapIcon}
                    alt="location"
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
            </div>
            <h2 className="h5 font-lg text-center font-weight-xmd mt-4">
              
              <br />
              Shanghai, PR China
            </h2>
          </div>
        </div>

        <div className="col-md-4 mb-3 mb-lg-0">
          <div className="  text-center">
            <div className="radius-5">
              <div className="shadow-inner  ui-icon ui-icon-lg ">
                <div className="ui-icon-inner">
                  <img src={emailIcon} alt="email" width="100%" height="100%" />
                </div>
              </div>
            </div>
            <p className="text-alt mb-0 mt-3 font-med shadow-inner radius-2 px-3 py-1">
              Email : kafahad1999@gmail.com
            </p>
          </div>
        </div>

        <div className="col-md-4 mb-3 mb-lg-0">
          <div className="text-center">
            <div className="radius-5">
              <div className="shadow-inner  ui-icon ui-icon-lg ">
                <div className="ui-icon-inner">
                  <img src={loveIcon} alt="phone" width="100%" height="100%" />
                </div>
              </div>
            </div>
            <p className="text-alt mb-0 mt-3 font-med shadow-inner radius-2 px-3 py-1">
              Phone : +8618516036259
            </p>
          </div>
        </div>

        <div className="col-md-4 mb-3 mb-lg-0">
          <div className="  text-center">
            <div className="radius-5">
              <div className="shadow-inner  ui-icon ui-icon-lg ">
                <div className="ui-icon-inner">
                  <img
                    src={codeIcon}
                    alt="website"
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
            </div>
            <p className="text-alt mb-0 mt-3 font-med shadow-inner radius-2 px-3 py-1">
              Site : www.kawsarahmedfahad.vercel.app/
            </p>
          </div>
        </div>
      </div>
      {/* Details */}
    </>
  );
};
