import blog1 from '../assets/images/blog/1.jpg';
import quotationIcon from '../assets/images/icon/Dark theme/quotation.gif';
// import { blogOne } from '../components/blog/BlogContent/contents/Blog1/ContentOne';

export const blogWithImage = [
  {
    img: blog1,
    title: 'Which design layout solution should you use ?',
    date: '13 Dec',
    postBy: 'faha1999',
    tag: 'Web Design',
    comments: 2,
    details: `Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
    laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi 
    architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit 
    aspernatur aut odit aut fugit,
    sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. `,
    url: '/content/contentOne',
  },
];

export const blogWithOutImage = [
  {
    title: 'Frontend Mentor Challenges Solutions',
    date: '10 Nov',
    postBy: 'faha1999',
    tag: 'Web Development',
    comments: 2,
    details: `Here is the collection of all Frontend Mentor challenge solutions by Kawsar Ahmed Fahad (faha1999). Here can find links to live demos and challenges on Frontend Mentor if you want to try them out. Happy coding :)`,
    url: '/content/contentTwo',
  },
];

export const blockquoteBlog = [
  {
    img: quotationIcon,
    title: 'A blockquote blog post style display',
    date: '13 Dec',
    postBy: 'faha1999',
    tag: 'Web Design',
    comments: 2,
    details: `"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
    totam rem aperiam, eaque ipsa quae ab illo
    inventore veritatis et quasi architecto beatae vitae dicta"`,
    url: '/content/contentThree',
    author: 'Stacy Taylor',
  },
];
