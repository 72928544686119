import React from 'react';
import suitcase from '../../../../../assets/images/icon/Dark theme/suitcase.gif';
import {
  experienceDetails,
  experienceDetailsPresent,
} from '../../../../../data/aboutData';
import { AiFillCalendar } from 'react-icons/ai';
import { motion } from 'framer-motion';

const down = {
  offscreen: { y: 50, opacity: 0 },
  onscreen: {
    x: 0,
    y: 0,
    opacity: 1,
    transition: { duration: 0.7 },
  },
};

export const ExperienceDetails = () => {
  return (
    <>
      <div className="row ">
        <div className="col-md-12">
          <div className="timeline" data-aos="fade-down">
            <div className="outer radius-2 position-absolute">
              <div className="ui-icon ui-icon-md inner ">
                <div className="ui-icon-inner">
                  <img
                    src={suitcase}
                    alt="Experience"
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
            </div>

            <div className="pt-5">
              {/* Details with present */}
              {experienceDetailsPresent.map((data) => (
                <motion.div
                  className="timeline-content"
                  key={data.title}
                  initial={'offscreen'}
                  whileInView={'onscreen'}
                  viewport={{ once: false, amount: 0.5 }}
                  variants={down}
                >
                  <div className="mb-1">
                    <h6 className="font-weight-xmd font-med mb-1 orange-text-color">
                      {data.title}
                    </h6>
                    <div className="font-sm text-alt mb-1">
                      <span className="fa fa-calendar me-2 text-muted">
                        <AiFillCalendar />
                      </span>
                      {data.duration}
                      <span className="current">{data.current}</span>
                    </div>
                  </div>
                  <p className="mb-0">
                    {data.details}{' '}
                    <small className="orange-text-color">
                      {data.quotation}
                    </small>
                  </p>
                </motion.div>
              ))}
              {/* Details with present */}

              {/* Details */}
              {experienceDetails.map((data) => (
                <motion.div
                  className="timeline-content"
                  key={data.title}
                  initial={'offscreen'}
                  whileInView={'onscreen'}
                  viewport={{ once: false, amount: 0.5 }}
                  variants={down}
                >
                  <div className="mb-1">
                    <h6 className="font-weight-xmd font-med mb-1 orange-text-color">
                      {data.title}
                    </h6>
                    <div className="font-sm text-alt mb-1">
                      <span className="fa fa-calendar me-2 text-muted">
                        <AiFillCalendar />
                      </span>
                      {data.duration}
                    </div>
                  </div>
                  <p className="mb-0">
                    {data.details}{' '}
                    <small className="orange-text-color">
                      {data.quotation}
                    </small>
                  </p>
                </motion.div>
              ))}
              {/* Details */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
