import React from 'react';
import { DividerLeft } from '../partials/global partials/Hr';
import { PortfolioFilter } from './dist/PortfolioFilter';

export const Portfolio = () => {
  return (
    <>
      <section id="portfolio" className="shadow bg-dark radius-2 p-5 mb-5 work">
        <div className="row mb-5">
          <div className="col">
            <h6 className="font-weight-lg  mb-0 text-uppercase mb-2">
              My works
            </h6>
            <DividerLeft />
          </div>
        </div>

        <PortfolioFilter />
      </section>
    </>
  );
};
