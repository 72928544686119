import React from 'react';
import { BlogHome } from '../components/blog/BlogHome';
import { BlogHomeNav } from '../components/blog/MobileNav/BlogHomeNav';

export const Blog = () => {
  return (
    <>
      <BlogHomeNav />
      <BlogHome />
    </>
  );
};
