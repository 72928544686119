import React from 'react';
import { sidebarMenu } from '../data/sidebarMenu';
import { Link } from 'react-scroll';

export const MobileHeader = () => {
  return (
    <>
      <header className="mobileMenu">
        <nav className="shadow bg-dark radius-2">
          <ul>
            {sidebarMenu.map((route) => (
              <li className="" key={route.id}>
                <Link
                  activeClass="active"
                  to={route.id}
                  key={route.name}
                  className="link inner radius-2"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={500}
                >
                  <div className="align-items-center">
                    <div className="text-center gap-3">
                      <div className="icon">
                        <img
                          src={route.icon}
                          alt={route.name}
                          width="100%"
                          height="100%"
                        />
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </header>
    </>
  );
};
