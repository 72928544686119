import React from 'react';
import { coding } from '../../../../../data/aboutData';
import { motion } from 'framer-motion';

const up = {
  offscreen: { y: -50, opacity: 0 },
  onscreen: {
    x: 0,
    y: 0,
    opacity: 1,
    transition: { duration: 0.7 },
  },
};

export const Coding = () => {
  return (
    <>
      <div className="row vc skill dev mb-5">
        <div className="col-md-11">
          <ul className="skills-list list-inline mb-0">
            {coding.map((data) => (
              <li key={data.title}>
                <h6 className="font-weight-xmd font-med text-uppercase">
                  {data.title}
                </h6>
                <div className="progress mb-3">
                  <div className="percentage" style={data.style}></div>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <motion.div
          className="col-lg-1"
          initial={'offscreen'}
          whileInView={'onscreen'}
          viewport={{ once: false, amount: 0.5 }}
          variants={up}
        >
          <div className="title dev font-med">
            <span>Coding</span>
          </div>
        </motion.div>
      </div>
    </>
  );
};
