import React from 'react';
import { ContactDetails } from './dist/ContactDetails';
import { DividerLeft } from '../partials/global partials/Hr';

export const Contact = () => {
  return (
    <>
      <section id="contact" className="shadow bg-dark radius-2 p-5 mb-5">
        <div className="row mb-5">
          <div className="col">
            <h6 className="font-weight-lg  mb-0 text-uppercase mb-2">
              Map location
            </h6>
            <DividerLeft />
          </div>
        </div>

        <ContactDetails />
      </section>
    </>
  );
};
