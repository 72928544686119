import React from 'react';
import Typewriter from 'typewriter-effect';
import faha1999 from '../../assets/images/faha1999.webp';
import { homeData } from '../../data/homeData';
import { DividerLgCenter } from '../partials/global partials/Hr';
import { motion } from 'framer-motion';

const up = {
  offscreen: { y: -100, opacity: 0 },
  onscreen: {
    x: 0,
    y: 0,
    opacity: 1,
    transition: { duration: 1 },
  },
};

const UlLI = {
  offscreen: { y: -40, opacity: 0 },
  onscreen: {
    x: 0,
    y: 0,
    opacity: 1,
  },
};

const UlLIItem = {
  offscreen: { opacity: 0 },
  onscreen: { opacity: 1, transition: { delay: 1 } },
};

export const Home = () => {
  return (
    <section className="shadow bg-dark radius-2 p-5 mb-5 text-center" id="home">
      <div className="row v-centred">
        <div className="col-md-12">
          <motion.div
            className="mobile p-5"
            initial={'offscreen'}
            whileInView={'onscreen'}
            viewport={{ once: false, amount: 0.5 }}
            transition={{ staggerChildren: 0.5 }}
            variants={up}
          >
            <div className="profile-image">
              <div className="profile-image-border">
                <img
                  className="rounded-circle"
                  src={faha1999}
                  alt="Kawsar Ahmed Fahad"
                />
              </div>
            </div>

            <h1 className="font-weight-xmd mb-0 pt-5">
              I'm Kawsar Ahmed Fahad
            </h1>
          </motion.div>

          <motion.h1
            className="mobile-d-none font-weight-xmd mb-0"
            initial={'offscreen'}
            whileInView={'onscreen'}
            viewport={{ once: false, amount: 0.5 }}
            transition={{ staggerChildren: 0.5 }}
            variants={up}
          >
            Kawsar Ahmed Fahad
          </motion.h1>

          <div className="item">
            <h6 className="h5 text-alt mt-1 mb-0 p-3">
              <Typewriter
                options={{
                  strings: ['Front-end Developer', 'Designer'],
                  autoStart: true,
                  loop: true,
                }}
              />
            </h6>
          </div>

          <motion.ul
            className="list-inline mb-0 mt-4 pb-4"
            initial={'offscreen'}
            whileInView={'onscreen'}
            viewport={{ once: false, amount: 0.5 }}
            variants={UlLI}
          >
            {homeData.map((data) => (
              <motion.li
                variants={UlLIItem}
                key={data.id}
                className="list-inline-item outer radius-2 me-1 mb-2 mb-lg-0 gap-3"
              >
                <a
                  href={data.url}
                  target="_blank"
                  rel="noreferrer"
                  className="inner btn btn-round btn-icon"
                >
                  <span className="btn-icon-inner">{data.icon}</span>
                </a>
              </motion.li>
            ))}
          </motion.ul>
        </div>

        <DividerLgCenter />
      </div>
    </section>
  );
};
