import React from 'react';
import { Home } from '../../components/home/Home';
import { About } from '../../components/about/About';
import { Portfolio } from '../../components/Portfolio/Portfolio';
import { Blogs } from '../../components/blogs/Blogs';
import { Contact } from '../../components/Contact/Contact';

export const Content = () => {
  return (
    <div className="content">
      <Home />
      <About />
      <Portfolio />
      <Blogs />
      <Contact />
    </div>
  );
};
