import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { EasyBankLandingPage } from './EasyBank/EasyBankLandingPage';
import { Manushoye } from './SpaceTourism/Manushoye/Manushoye';
import { SpaceTourismMultiPage } from './SpaceTourism/SpaceTourismMultiPage';

export const PortfolioLayout = () => {
  return (
    <>
      <div className="container">
        <section className="projects shadow bg-dark radius-2 p-5 mt-5 mb-5">
          <Routes>
            <Route
              path="easybank-landing-page"
              element={<EasyBankLandingPage />}
            />
            <Route
              path="space-tourism-multi-page"
              element={<SpaceTourismMultiPage />}
            />
            <Route path="manushoye" element={<Manushoye />} />
          </Routes>
        </section>
      </div>
    </>
  );
};
